var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pvp-table stripped adaptive rating-table"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-row",class:{ penalty: _vm.rating.hasPenalty }},[_c('cell-header',{attrs:{"t":"places.single"}}),_c('cell-header',[_vm._v(_vm._s(_vm.playersTypeTitle))]),_c('cell-header',{attrs:{"t":"ratings.table_country"}}),_c('cell-header',{attrs:{"t":"ratings.table_matches"}}),_c('cell-header',{attrs:{"t":"ratings.table_win"}}),_c('cell-header',[_vm._v(_vm._s(_vm.$t('ratings.table_win'))+"(%)")]),_c('cell-header',{attrs:{"t":"ratings.single"}}),(_vm.rating.hasPenalty)?_c('cell-header',{attrs:{"t":"global.penalties"}}):_vm._e()],1)]),_c('div',{staticClass:"table-body"},_vm._l((_vm.rating.items),function(item,index){return _c('div',{key:index,staticClass:"table-row",class:{ penalty: _vm.rating.hasPenalty }},[_c('cell-simple',{attrs:{"t":"places.single","value":item.place}}),(_vm.rating.type === 1)?_c('BaseUserLinkWithoutStore',{attrs:{"data":{
          hash: item.hash,
          name: item.name,
          avatarUrl: item.image,
        }}}):_vm._e(),(_vm.rating.type === 2)?_c('BaseTeamLinkWithoutStore',{attrs:{"data":{
          hash: item.hash,
          name: item.name,
          avatarUrl: item.image,
        }}}):_vm._e(),_c('cell-simple',{attrs:{"t":"ratings.table_country"}},[_c('country-tag',{attrs:{"id":item.idCountry}})],1),_c('cell-simple',{attrs:{"t":"ratings.table_matches","value":item.matches}}),_c('cell-simple',{attrs:{"t":"ratings.table_win","value":item.win}}),_c('cell-simple',[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('ratings.table_win'))+"(%) ")]),_vm._v(" "+_vm._s(item.percentWin)+"% ")],2),_c('cell-simple',{attrs:{"t":"ratings.single","value":item.finedValue}}),(_vm.rating.hasPenalty)?_c('cell-simple',{staticClass:"penalty",attrs:{"t":"global.penalties"}},[_vm._v(" "+_vm._s(_vm._f("filterFloat")((item.value - item.finedValue),5))+" "),(item.penaltyDescription)?_c('pvp-btn',{attrs:{"icon-left":"info","variant":"clear"},on:{"click":function($event){return _vm.showPenaltyPopup(item)}}}):_vm._e()],1):_vm._e()],1)}),0),_c('penalty-popup',{attrs:{"info":_vm.penaltyInfo},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }