<template>
  <div
    class="boost-layout"
    :class="{ 'boost-layout--rating': isBgRating }"
  >
    <div class="boost-layout__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoostLayout',
  props: {
    isBgRating: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.boost-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  background-repeat: no-repeat;
}

.boost-layout--rating {
  background-size: 100% auto;
  background-image: url('~@assets/img/boost/rating_bg_mobile.jpeg');

  @include min-tablet() {
    min-height: 100%;
    background-image: url('~@assets/img/boost/rating_bg_desktop.jpeg');
  }
}

.boost-layout__content {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
