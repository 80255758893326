<template>
  <div class="pvp-table stripped adaptive rating-table">
    <div class="table-header">
      <div :class="{ penalty: rating.hasPenalty }" class="table-row">
        <cell-header t="places.single" />
        <cell-header>{{ playersTypeTitle }}</cell-header>
        <cell-header t="ratings.table_country" />
        <cell-header t="ratings.table_matches" />
        <cell-header t="ratings.table_win" />
        <cell-header>{{ $t('ratings.table_win') }}(%)</cell-header>
        <cell-header t="ratings.single" />
        <cell-header v-if="rating.hasPenalty" t="global.penalties" />
      </div>
    </div>

    <div class="table-body">
      <div
        v-for="(item, index) in rating.items"
        :key="index"
        :class="{ penalty: rating.hasPenalty }"
        class="table-row"
      >
        <cell-simple t="places.single" :value="item.place" />

        <BaseUserLinkWithoutStore
          v-if="rating.type === 1"
          :data="{
            hash: item.hash,
            name: item.name,
            avatarUrl: item.image,
          }"
        />
        <BaseTeamLinkWithoutStore
          v-if="rating.type === 2"
          :data="{
            hash: item.hash,
            name: item.name,
            avatarUrl: item.image,
          }"
        />

        <cell-simple t="ratings.table_country">
          <country-tag :id="item.idCountry" />
        </cell-simple>
        <cell-simple
          t="ratings.table_matches"
          :value="item.matches"
        />
        <cell-simple t="ratings.table_win" :value="item.win" />

        <cell-simple>
          <template slot="title">
            {{ $t('ratings.table_win') }}(%)
          </template>
          {{ item.percentWin }}%
        </cell-simple>

        <cell-simple t="ratings.single" :value="item.finedValue" />

        <cell-simple
          v-if="rating.hasPenalty"
          class="penalty"
          t="global.penalties"
        >
          {{ (item.value - item.finedValue) | filterFloat(5) }}
          <pvp-btn
            v-if="item.penaltyDescription"
            icon-left="info"
            variant="clear"
            @click="showPenaltyPopup(item)"
          />
        </cell-simple>
      </div>
    </div>

    <penalty-popup v-model="isOpen" :info="penaltyInfo" />
  </div>
</template>

<script>
import CellHeader from '@components/TableCells/CellHeader.vue';
import BaseTeamLinkWithoutStore from '@components/BaseComponents/BaseTeamLinkWithoutStore.vue';
import BaseUserLinkWithoutStore from '@components/BaseComponents/BaseUserLinkWithoutStore.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import PenaltyPopup from '@components/RatingComponents/PenaltyPopup.vue';

export default {
  name: 'RatingFullTable',
  components: {
    PenaltyPopup,
    CellSimple,
    BaseTeamLinkWithoutStore,
    BaseUserLinkWithoutStore,
    CellHeader,
  },
  props: {
    rating: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    penaltyInfo: {},
  }),
  computed: {
    playersTypeTitle() {
      return this.rating.type === 1
        ? this.$t('ratings.table_player')
        : this.$t('ratings.table_team');
    },
  },
  methods: {
    showPenaltyPopup(item) {
      this.penaltyInfo = {
        hash: item.hash,
        isTeam: this.rating.type === 2,
        list: item.penalties,
      };
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 2fr 1.3fr 0.8fr repeat(3, 1fr);
      &.penalty {
        grid-template-columns: 0.8fr 1.7fr 1.3fr 0.7fr 0.7fr 0.9fr 0.8fr 1.1fr;
      }
    }
  }

  &-column {
    @include max-laptop() {
      ::v-deep .column-title {
        flex: 0 0 85px;
      }
    }
  }
}

.rating-table {
  margin-top: 30px;

  .table-column {
    &.place {
      padding-left: 32px;
    }

    &.team {
      padding-left: 20px;
    }

    &.penalty {
      .button {
        color: rgba(white, 0.5);
        margin-left: 4px;
      }
    }
  }
}
</style>
